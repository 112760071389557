import Feature from "ol/Feature";
import RichLogger from "./richlogger";
import { Fill, Stroke, Circle, Style, RegularShape, Text } from "ol/style";
const logger = new RichLogger("createFeature");
const createFeature = (geom, id, customGeoms, label = null) => {
  const newFeature = new Feature({
    geometry: geom,
  });
  newFeature.setStyle(setStyle(customGeoms, label));
  newFeature.setId(id);
  return newFeature;
};

const setStyle = (customGeoms, label, resolution) => {
  const {
    geom_fill_color,
    geom_stroke_color,
    geom_shape,
    geom_radius,
    geom_stroke_width,
  } = customGeoms;
  let {
    font,
    fontSize,
    placement,
    fontFillColor,
    fontStrokeColor,
    fontStrokeWidth,
    baseline,
    align,
    maxReso,
    display,
  } = customGeoms;
  if (!font) font = "Arial";
  if (!fontSize) fontSize = 50;
  if (!placement) placement = "point";
  if (!fontFillColor) fontFillColor = geom_fill_color;
  if (!fontStrokeColor) fontStrokeColor = geom_stroke_color;
  if (!align) align = "center";
  if (!baseline) baseline = "middle";
  if (!fontStrokeWidth) fontStrokeWidth = 3;
  if (!maxReso) maxReso = 1200;
  if (!display) display = "wrap";

  logger.log("createFeature", geom_fill_color, geom_stroke_color, geom_shape);

  const getText = function (text, display, resolution) {
    const type = display;
    const maxResolution = maxReso;
    if (resolution > maxResolution) {
      text = "";
    } else if (display === "hide") {
      text = "";
    } else if (type === "shorten") {
      text = text.trunc(12);
    } else if (type == "wrap" && (!placement || placement !== "line")) {
      text = stringDivider(text, 16, "\n");
    }

    return text;
  };

  const stroke = new Stroke({
    color: geom_stroke_color,
    width: geom_stroke_width,
  });
  const fill = new Fill({
    color: geom_fill_color,
  });
  let pointShape = new Circle({
    radius: geom_radius,
    fill: new Fill({
      color: geom_fill_color,
    }),
    stroke: new Stroke({
      color: geom_stroke_color,
      width: geom_stroke_width,
    }),
  });
  if (geom_shape !== "circle") {
    pointShape = new RegularShape({
      fill: fill,
      stroke: stroke,
      points: 4,
      radius: geom_radius,
      angle: Math.PI / 4,
    });
  }
  /*const labelStyle = new Text({
    textAlign: align,
    textBaseline: baseline,
    font: font,
    fill: new Fill({ color: fontFillColor }),
    stroke: new Stroke({ color: fontStrokeColor, width: fontStrokeWidth }),
    offsetX: 0,
    offsetY: 0,
    placement: placement,
    text: getText(label, display, resolution),
  });*/

  var st = new Style({
    fill: fill,
    stroke: stroke,
    image: pointShape,
    // text: labelStyle,
  });

  return st;
};
// https://stackoverflow.com/questions/14484787/wrap-text-in-javascript
const stringDivider = (str, width, spaceReplacer) => {
  if (str.length > width) {
    let p = width;
    while (p > 0 && str[p] != " " && str[p] != "-") {
      p--;
    }
    if (p > 0) {
      let left;
      if (str.substring(p, p + 1) == "-") {
        left = str.substring(0, p + 1);
      } else {
        left = str.substring(0, p);
      }
      const right = str.substring(p + 1);
      return left + spaceReplacer + stringDivider(right, width, spaceReplacer);
    }
  }
  return str;
};
const Features = {
  createFeature,
  setStyle,
};
export default Features;
