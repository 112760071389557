import { createContext, useState } from "react";
import { useSelector } from "react-redux";
const MapContext = createContext(true);
const MapProvider = (props) => {
  const [map, setMap] = useState(null);
  //const [zoom] = useState(3);
  const [srid, setSrid] = useState(null);
  const [extent, setExtent] = useState(null);
  const [layers, setLayers] = useState([]);
  const [addedGeom, setAddedGeom] = useState(false);
  const [debug, setDebug] = useState(false);
  const [tool, setTool] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(2);
  const [minZoomLevel, setMinZoomLevel] = useState(null);
  const [geolocation, setGeolocation] = useState(null);
  const [wmsLayers, setWmsLayers] = useState([]);
  const [ToolAddpoint, setToolAddpoint] = useState(false);
  const [ToolAddpolygon, setToolAddpolygon] = useState(false);
  const [ToolGeolocalize, setToolGeolocalize] = useState(false);
  const [ToolMeasure, setToolMeasure] = useState(null);
  const [activeLayer, setAactiveLayer] = useState(null);
  const [doInfo, setDoInfo] = useState(null);
  const [doElementsFromLayer, setDoElementsFromLayer] = useState(null);
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [GiswaterFilters, setGiswaterFilters] = useState([]);
  const [ProjectProperties, setProjectProperties] = useState({});
  const [Toc, setToc] = useState([]);
  const [availableLayers, setAvailableLayers] = useState([]);
  const [customGeoms, setCustomGeoms] = useState({
    geom_stroke_color: "rgb(20, 255, 0,1)",
    geom_fill_color: "rgb(19, 39, 99,0.5)",
    geom_stroke_width: 1,
    geom_shape: "circle",
    geom_radius: 4,
    measure_fill_color: "rgba(222,218,125,0.63)",
    measure_stroke_color: "rgba(255,0,0,1)",
  });
  const [showTiledBg, setShowTiledBg] = useState(false);
  const [selectedTiled, setSelectedTiled] = useState(null);
  const [geojsonObject, setGeojsonObject] = useState(null);
  const [ToolsHelpersTexts, setToolsHelpersTexts] = useState({
    textStart: "Click to start drawing",
    textContinueLine: "Click to continue drawing the polygon", //Message to show when a user is drawing a polygon
    textContinuePolygon: "Click to continue drawing the line",
    textContinue: "Click to continue",
  });
  //backgrounds
  const [renderBg, setRenderBg] = useState(false);
  const [sourceBg, setSourceBg] = useState(null);
  const [bg, setBg] = useState(null);

  //dynamic vector layers
  const [vectorLayers, setVectorLayers] = useState([]);

  //Redux
  const zoom = useSelector((state) => state.zoom.level);

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
        zoom,
        wmsLayers,
        setWmsLayers,
        srid,
        setSrid,
        addedGeom,
        setAddedGeom,
        debug,
        setDebug,
        layers,
        setLayers,
        extent,
        setExtent,
        tool,
        setTool,
        geolocation,
        setGeolocation,
        ToolAddpoint,
        setToolAddpoint,
        ToolAddpolygon,
        setToolAddpolygon,
        ToolGeolocalize,
        setToolGeolocalize,
        activeLayer,
        setAactiveLayer,
        clickedCoordinates,
        setClickedCoordinates,
        doInfo,
        setDoInfo,
        GiswaterFilters,
        setGiswaterFilters,
        ProjectProperties,
        setProjectProperties,
        showTiledBg,
        setShowTiledBg,
        geojsonObject,
        setGeojsonObject,
        customGeoms,
        setCustomGeoms,
        setDoElementsFromLayer,
        doElementsFromLayer,
        Toc,
        setToc,
        availableLayers,
        setAvailableLayers,
        renderBg,
        setRenderBg,
        sourceBg,
        setSourceBg,
        bg,
        setBg,
        selectedTiled,
        setSelectedTiled,
        zoomLevel,
        setZoomLevel,
        minZoomLevel,
        setMinZoomLevel,
        vectorLayers,
        setVectorLayers,
        ToolMeasure,
        setToolMeasure,
        ToolsHelpersTexts,
        setToolsHelpersTexts,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};
// const SearchConsumer = SearchContext.Consumer;
// export { SearchProvider, SearchConsumer };
export { MapProvider };
export default MapContext;
