import RichLogger from "../../Shared/richlogger";
import OLTileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import { wms, imgwms } from "../Source";
import WFSFilters from "../../Shared/wfsfilters";
import { sendMessageToParent } from "../../Shared/iframe-communicator";
const logger = new RichLogger("components/Layers");
let layerList = [];
let layerDataList = [];
let cachedLayers = [];
let _filters = [];
const AddLayer = (props, map) => {
  if (!map) return;
  if (layerList.indexOf(props.name) === -1) {
    logger.log("Add QGIS Layer", props);
    const singletile = props.singletile ? props.singletile : false;
    WFSFilters.setWFSFlters(props)
      .then((msg) => {
        logger.success("setWFSFlters on AddLayer", msg);
        layerList.push(props.name);
        layerDataList.push(props);

        if (cachedLayers.indexOf(props.name) === -1) {
          logger.log("Layer is not cached");
          props.filters = `${props.name}:${WFSFilters.GetWMSFilters({
            filters: _filters,
            layer_name: props.name,
            layerAvailableFilters: msg,
            type: props.type,
          })}`;
          cachedLayers.push(props.name);
          if (singletile) {
            logger.log("Adding singletile layer");
            let imgLayer = new ImageLayer({
              source: imgwms(props),
              zIndex: 900,
              name: props.name,
            });
            // after rendering the layer, emit an event
            imgLayer.once("postrender", function (event) {
              sendMessageToParent({
                type: "loaded",
                what: "layer",
                layer: props.name,
              });
            });
            map.addLayer(imgLayer);
          } else {
            logger.log("Adding multile layer");
            let tileLayer = new OLTileLayer({
              source: wms(props),
              zIndex: 999,
              name: props.name,
            });
            // after rendering the layer, emit an event
            tileLayer.once("postrender", function (event) {
              sendMessageToParent({
                type: "loaded",
                what: "layer",
                layer: props.name,
              });
            });
            map.addLayer(tileLayer);
          }

          sendMessageToParent({
            type: "layers",
            layers: getLayerList(),
          });
        } else {
          logger.log("Layer is cached, make it visible");
          makeLayerVisible(map, props.name, true);
        }
      })
      .catch((e) => {
        logger.error("setWFSFlters", e);
      });
  } else {
    logger.log("Remove Layer", props.name);
    makeLayerVisible(map, props.name, false);
    layerList.splice(layerList.indexOf(props.name), 1);
  }
  return null;
};
const getLayerList = () => {
  return layerList;
};

const makeLayerVisible = (map, layername, visible) => {
  logger.log("makeLayerVisible", { name: layername, visible: visible });
  map.getLayers().forEach((layer) => {
    if (layer.get("name") && layer.get("name") === layername) {
      layer.setVisible(visible);
    }
  });
};

const reloadDisplayedLayers = (map, filters) => {
  logger.log("reloadDisplayedLayers", {
    filters: filters,
    cachedLayers: cachedLayers,
  });
  _filters = filters;
  if (!map) return;
  if (cachedLayers.length > 0) {
    getLayerList().forEach((layer) => {
      makeLayerVisible(map, layer, 1);
    });
  } else {
    layerDataList.forEach((layer) => {
      map.getLayers().forEach((layermap) => {
        if (layermap.get("name") && layermap.get("name") === layer.name) {
          let layerParams = layermap.getSource().getParams();
          WFSFilters.setWFSFlters(layer).then((msg) => {
            logger.success("setWFSFlters on reloadDisplayedLayers", msg);
            layerParams.FILTER = `${layer.name}:${WFSFilters.GetWMSFilters({
              filters: _filters,
              layer_name: layer.name,
              layerAvailableFilters: msg,
            })}`;
            layermap.getSource().updateParams(layerParams);
            cachedLayers.push(layer.name);
          });
        }
      });
    });
  }
};
const setFilters = (filters) => {
  logger.log("setFilters", filters);
  _filters = filters;
};
const resetCachedLayers = () => {
  logger.log("resetCachedLayers");
  cachedLayers = [];
};
const Layers = {
  AddLayer,
  getLayerList,
  reloadDisplayedLayers,
  setFilters,
  resetCachedLayers,
};
export default Layers;
