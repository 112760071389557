import {
  useContext,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import MapContext from "../../../Contexts/MapContext.js";
import GeoJSON from "ol/format/GeoJSON";
import OLVectorLayer from "ol/layer/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import RichLogger from "../../../Shared/richlogger";
import { sendMessageToParent } from "../../../Shared/iframe-communicator";
import { vector } from "../../Source";
let strokeColor = "#ff0000";
let fillColor = "#ff0000";
let geom_radius = 4;
let geom_shape = "circle";
let fontColor = "#000000";
let font = "arial";
let fontSize = 12;
let stroke_width = 4;
let offsetY = 10;
const logger = new RichLogger("components/GeoJSONLayer");
var styles = null;
var geoJsonLayers = [];
var styleFunction = function (feature) {
  return styles[feature.getGeometry().getType()];
};

let geojsonlayer = null;
const GeoJSONLayer = forwardRef((props, ref) => {
  const { map } = useContext(MapContext);
  const { source, zIndex, projectProperties, customGeoms } = props;
  const [render, setRender] = useState(false);
  strokeColor = customGeoms.geom_stroke_color;
  fillColor = customGeoms.geom_fill_color;
  geom_radius = customGeoms.geom_radius;
  geom_shape = customGeoms.geom_shape;
  stroke_width = customGeoms.stroke_width;

  // const [geoJsonLayers, setGeoJsonLayers] = useState([]);
  const remove = (name) => {
    logger.info("remove", name);
    map.getLayers().forEach((layer) => {
      if (!layer) return;
      if (layer.get("name") && layer.get("name") === name) {
        map.removeLayer(layer);
        geoJsonLayers.splice(geoJsonLayers.indexOf(name), 1);
        sendMessageToParent({
          type: "geoJSONlayers",
          layers: geoJsonLayers,
        });
      }
    });
  };

  const clear = () => {
    logger.info("clear", geoJsonLayers);
    geoJsonLayers.forEach((geojson) => {
      map.getLayers().forEach((layer) => {
        if (layer.get("name") && layer.get("name") === geojson) {
          layer.getSource().clear();
        }
      });
    });
    geoJsonLayers = [];
  };

  const addGeoJson = (geoJSONdata, options, name) => {
    logger.info("addGeoJson", geoJSONdata);
    let layerStyle = setGeoJSONStyle(options);

    let geojsonlayer = new OLVectorLayer({
      updateWhileInteracting: true,
      source: new vector({
        features: new GeoJSON().readFeatures(geoJSONdata),
        useSpatialIndex: true,
      }),
      style: function (feature) {
        return layerStyle[feature.getGeometry().getType()];
      },
      name: name,
    });
    var fea = geojsonlayer.getSource().getFeatures();
    fea.forEach(function (feature) {
      feature.setId(feature.get("id"));
      //set feauture label
      layerStyle[feature.getGeometry().getType()]
        .getText()
        .setText(feature.get("name"));
      var feaStyle = function (feature) {
        return layerStyle[feature.getGeometry().getType()];
      };

      feature.setStyle(feaStyle);
    });
    geoJsonLayers.push(name);
    map.addLayer(geojsonlayer);
    geojsonlayer.setZIndex(zIndex);
    sendMessageToParent({
      type: "geoJSONlayers",
      layers: geoJsonLayers,
    });
  };

  const setGeoJSONStyle = (options) => {
    logger.info("setGeoJSONStyle", options);
    strokeColor = options.strokecolor ? options.strokecolor : strokeColor;
    fillColor = options.fillcolor ? options.fillcolor : fillColor;
    fontColor = options.fontColor ? options.fontColor : fontColor;
    font = options.font ? options.font : font;
    fontSize = options.fontSize ? options.fontSize : fontSize;
    geom_radius = options.geom_radius ? options.geom_radius : geom_radius;
    stroke_width = options.stroke_width ? options.stroke_width : stroke_width;
    offsetY = options.offsetY ? options.offsetY : offsetY;
    //textColor
    var image = new CircleStyle({
      radius: 5,
      fill: null,
      stroke: new Stroke({ color: strokeColor, width: 1 }),
    });

    var textStyle = new Text({
      textAlign: "center",
      font: `${fontSize}px ${font}`,
      rotation: 0,
      fill: new Fill({ color: fontColor }),
      stroke: new Stroke({ color: fontColor, width: stroke_width }),
      // scale: 1.5,
      offsetX: 0,
      offsetY: offsetY,
      placement: "point",
      text: null,
    });

    return {
      Point: new Style({
        text: textStyle,
        image: new CircleStyle({
          radius: geom_radius,
          fill: new Fill({
            color: fillColor,
          }),
          stroke: new Stroke({
            color: strokeColor,
          }),
        }),
      }),
      LineString: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          width: stroke_width,
        }),
      }),
      MultiLineString: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          width: stroke_width,
        }),
      }),
      MultiPoint: new Style({
        text: textStyle,
        image: image,
      }),
      MultiPolygon: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          width: stroke_width,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      Polygon: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          lineDash: [4],
          width: stroke_width,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
      GeometryCollection: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          width: stroke_width,
        }),
        fill: new Fill({
          color: strokeColor,
        }),
        image: new CircleStyle({
          radius: geom_radius,
          fill: null,
          stroke: new Stroke({
            color: strokeColor,
          }),
        }),
      }),
      Circle: new Style({
        text: textStyle,
        stroke: new Stroke({
          color: strokeColor,
          width: stroke_width,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      }),
    };
  };
  useImperativeHandle(ref, () => ({
    remove: remove,
    clear: clear,
    addGeoJson: addGeoJson,
  }));

  useEffect(() => {
    if (!map) return;
  }, [map]);
  return null;
});
export default GeoJSONLayer;
