import WKTFormatter from "./wktformatter";
import { sendMessageToParent } from "./iframe-communicator";
import { setZoom } from "../redux/actions/zoom";

import { getCenter } from "ol/extent";
import RichLogger from "./richlogger";

export const HighLiteGeom = (
  geom,
  zoom,
  vectorLayerRef,
  mapObject,
  draw,
  dispatch,
  setZoomLevel
) => {
  var rawGeometry = WKTFormatter.StringToGeom(geom);

  if (rawGeometry) {
    //add element
    if (draw)
      vectorLayerRef.current.addFeature(rawGeometry, "highlight", false);

    //center map on element
    mapObject.getView().setCenter(getCenter(rawGeometry.getExtent()));
    //zoom to element
    if (zoom.type === "level") {
      let currentZoomLevel = mapObject.getView().getZoom();

      let zoomLevel = Number(zoom.zoomLevel);

      if (!isNaN(zoomLevel)) {
        if (
          mapObject.getView().getMaxZoom() >= zoomLevel &&
          mapObject.getView().getMinZoom() <= zoomLevel
        ) {
          setZoomLevel(zoomLevel);
        } else {
          const fitZoom =
            zoomLevel < currentZoomLevel
              ? mapObject.getView().getMinZoom() - 1
              : mapObject.getView().getMaxZoom() + 1;
          sendMessageToParent({
            type: "error",
            error: "ZoomLevel Overflow",
            extraInfo: {
              minZoom: mapObject.getView().getMinZoom(),
              maxZoom: mapObject.getView().getMaxZoom(),
            },
          });
          setZoomLevel(fitZoom);
        }
      }
    } else {
      mapObject.getView().fit(rawGeometry.getExtent(), mapObject.getSize());
    }
  } else {
    sendMessageToParent({
      type: "error",
      error: "Invalid geometry",
    });
  }
};
