import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import Logger from "../richlogger";
import IframeCommunicator, {
  sendMessageToParent,
} from "../../Shared/iframe-communicator";
import { setDevBugCookie } from "../cookies";
import { incrementZoom, decrementZoom } from "../../redux/actions/zoom";
import MapContext from "../../Contexts/MapContext.js";
import Layers from "../../Components/Layers";
import { HighLiteGeom } from "../Highlite";
import WFSFilters from "../../Shared/wfsfilters";
import useBackgrounds from "../../hooks/useBackgrounds";
import useVectorLayers from "../../hooks/useVectorLayers";
import useLayers from "../../hooks/useLayers";
const logger = new Logger("Messages");
let _wmsLayers = [];
let _map = null;
let _toc = [];
let _extent = null;
let _activeLayer = null;
let _GiswaterFilters = [];
let _ProjectProperties = {};
let _ProjectLayers = {};
let _availableLayers = [];
let _selectedTiled;
let _currentZoom;
export const Messages = (props, vectorLayerRef, geoJSONLayerRef) => {
  const {
    wmsLayers,
    map,
    setTool,
    setToolAddpoint,
    setToolAddpolygon,
    setToolGeolocalize,
    setToolMeasure,
    extent,
    setDoInfo,
    setDoElementsFromLayer,
    activeLayer,
    setAactiveLayer,
    GiswaterFilters,
    setGiswaterFilters,
    ProjectProperties,
    setShowTiledBg,
    layers,
    setGeojsonObject,
    setCustomGeoms,
    customGeoms,
    Toc,
    availableLayers,
    setRenderBg,
    setSelectedTiled,
    selectedTiled,
    setBg,
    zoomLevel,
    setZoomLevel,
    setToolsHelpersTexts,
  } = useContext(MapContext);
  const { getLayerProperties } = useLayers();

  const { addVectorLayer, addGeomToVectorLayer, removeGeomFromLayer } =
    useVectorLayers(props);

  const { getBackground } = useBackgrounds(props);
  const dispatch = useDispatch();
  // on component mount
  useEffect(() => {
    const iframeCommunicator = new IframeCommunicator({
      sessionToken: props.iframeParams.sessionToken,
      domId: props.iframeParams.domId,
    });
    window.addEventListener("message", (evt) =>
      iframeCommunicator.onMessageReceived(evt, processMessage)
    );
  }, []);
  const processMessage = (event) => {
    logger.log("processMessage", event.data);
    switch (event.data.type) {
      case "setDebug":
        const debug = event.data.what ? Boolean(event.data.what) : false;
        setDevBugCookie({ debug: debug });
        break;
      case "zoomIn":
        //dispatch(incrementZoom());
        if (_map.getView().getMaxZoom() > _currentZoom + 1) {
          setZoomLevel(_currentZoom + 1);
        } else {
          logger.error("zoomOut zoomIn overflow");
          setZoomLevel(_map.getView().getMaxZoom());
        }
        break;
      case "zoomOut":
        if (_map.getView().getMinZoom() < _currentZoom - 1) {
          setZoomLevel(_currentZoom - 1);
        } else {
          logger.error("zoomOut minZoom overflow");
          setZoomLevel(_map.getView().getMinZoom());
        }

        //  dispatch(decrementZoom());
        break;
      case "loadWMSAvailableLayers":
        logger.log("loadWMSAvailableLayers", event.data);
        sendMessageToParent({
          type: "availableWMSLayers",
          layers: _wmsLayers,
        });

        break;
      case "toggleLayer":
        const properties = getLayerProperties(
          _availableLayers,
          event.data.layer
        );
        const gutter =
          event.data.gutter !== null ? event.data.gutter : properties.gutter;
        const transparent =
          event.data.transparent !== null
            ? event.data.transparent
            : properties.layer_transparent;
        const singletile =
          event.data.singletile !== null
            ? event.data.singletile
            : properties.singletile;
        const layerType = event.data.layerType
          ? event.data.layerType.toLowerCase()
          : null;
        if (layerType === "vector") {
          addVectorLayer({ name: event.data.layer });
        } else {
          Layers.AddLayer(
            {
              name: event.data.layer,
              project_id: props.iframeParams.project_id,
              project_name: props.iframeParams.name,
              token: props.iframeParams.token,
              api_uri: props.iframeParams.api_uri,
              type: props.iframeParams.type,
              gutter: gutter,
              singletile: singletile,
              transparent: transparent,
            },
            _map
          );
        }
        break;

      case "AddGeom":
        logger.log("AddGeom", event.data.geom);
        setTool(`add_${event.data.geom}`);
        if (event.data.geom === "Point") {
          setToolAddpoint(true);
        } else if (
          event.data.geom === "Polygon" ||
          event.data.geom === "Line"
        ) {
          setToolAddpolygon(true);
        }
        break;
      case "Geolocalize":
        logger.log("Geolocalize", event.data.toggle);
        setToolGeolocalize(event.data.toggle);
        break;
      case "clear":
        logger.log("clear");
        vectorLayerRef.current.clear();
        break;

      case "highlight":
        logger.log("highlight", event.data);
        HighLiteGeom(
          event.data.geom,
          event.data.zoom,
          vectorLayerRef,
          _map,
          true,
          dispatch,
          setZoomLevel
        );
        break;
      case "infoFromCoordinates":
        if (!event.data.layer && !_activeLayer) {
          sendMessageToParent({
            type: "error",
            error: "no layer",
          });
          return;
        }
        setDoInfo({
          info: event.data.info,
          layer: event.data.layer ? event.data.layer : _activeLayer,
          hitTolerance: event.data.hitTolerance,
          format: event.data.format,
        });
        break;
      case "getElementsFromLayer":
        setDoElementsFromLayer({
          layer: event.data.layer,
          limit: event.data.limit,
          format: event.data.format,
          extent: event.data.extent,
        });
        break;
      case "zoomToCoordinates":
        HighLiteGeom(
          `POINT(${event.data.coordinates[0]} ${event.data.coordinates[1]})`,
          { type: "level", zoomLevel: event.data.zoomLevel },
          vectorLayerRef,
          _map,
          false,
          dispatch,
          setZoomLevel
        );
        break;
      case "zoomToExtent":
        logger.log("zoomToExtent");
        _map.getView().fit(_extent, _map.getSize());
        break;

      case "setActiveLayer":
        logger.log("setActiveLayer", event.data.layer);
        setAactiveLayer(event.data.layer);
        break;

      case "getActiveLayer":
        logger.log("getActiveLayer", _activeLayer);
        sendMessageToParent({
          type: "activeLayer",
          activeLayer: _activeLayer,
        });
        break;
      case "reloadDisplayedLayers":
        logger.log("reloadDisplayedLayers");
        Layers.reloadDisplayedLayers(_map, _GiswaterFilters);
        break;
      case "toggleGiswaterTiled":
        logger.log("toggleGiswaterTiled", _ProjectProperties);
        setShowTiledBg(false);
        if (event.data.tiled) {
          logger.log("toggleGiswaterTiled set tiled:", event.data.tiled);
          setSelectedTiled(event.data.tiled);
        }
        setShowTiledBg(event.data.toggle);
        sendMessageToParent({
          type: "giswaterTiledBackgroundDisplayed",
          visible: event.data.toggle,
          tiled: _selectedTiled,
        });
        break;
      case "setGiswaterFilters":
        logger.log("setGiswaterFilters", event.data);
        setGiswaterFilters(event.data.filters);
        break;
      case "getGiswaterLayerAvailableFilters":
        logger.log("getGiswaterLayerAvailableFilters", event.data);
        WFSFilters.setWFSFlters({
          name: event.data.name,
          project_id: props.iframeParams.project_id,
          project_name: props.iframeParams.name,
          token: props.iframeParams.token,
          api_uri: props.iframeParams.api_uri,
        })
          .then((msg) => {
            logger.success("getGiswaterLayerAvailableFilters", msg);
            sendMessageToParent({
              type: "GiswaterLayerAvailableFilters",
              filters: msg,
            });
          })
          .catch((e) => {
            logger.error("getGiswaterLayerAvailableFilters", e);
          });
        break;

      case "addGeoJSON":
        if (typeof event.data.geoJSON != "object") {
          sendMessageToParent({
            type: "error",
            error: "invalid geoJSON",
          });
          return;
        }
        setGeojsonObject(true);
        geoJSONLayerRef.current.addGeoJson(
          event.data.geoJSON,
          event.data.options,
          event.data.name
        );
        break;

      case "clearGeoJSON":
        logger.log("clearGeoJSON", event.data);
        geoJSONLayerRef.current.clear();
        setGeojsonObject(false);
        break;
      case "removeGeoJSONLayer":
        logger.log("removeGeoJSONLayer", event.data);
        if (!event.data.name) return;
        if (!geoJSONLayerRef.current) return;
        geoJSONLayerRef.current.remove(event.data.name);
        break;
      case "setCustomColors":
        logger.log("setCustomColors", event.data);
        setCustomGeoms(event.data.properties);
        break;
      case "getToc":
        logger.log("getToc", event.data);
        sendMessageToParent({
          type: "getToc",
          toc: _toc,
        });
        break;
      case "changeBackground":
        logger.log("changeBackground", event.data);
        setBg(event.data.newBackground);
        setRenderBg(false);
        break;
      case "centerMap":
        logger.log("centerMap", event.data);
        _map.getView().setCenter(event.data.coordinates);
        break;
      case "drawGeometryInLayer":
        logger.log("drawGeometryInLayer", event.data);
        //override styles

        const {
          geom_stroke_color,
          geom_fill_color,
          geom_radius,
          geom_stroke_width,
          font,
          fontSize,
          placement,
          fontFillColor,
          fontStrokeColor,
          fontStrokeWidth,
          baseline,
          align,
          display,
          maxReso,
        } = event.data.style;
        if (geom_stroke_color)
          customGeoms.geom_stroke_color = geom_stroke_color;
        if (geom_fill_color) customGeoms.geom_fill_color = geom_fill_color;
        if (geom_radius) customGeoms.geom_radius = geom_radius;
        if (geom_stroke_width)
          customGeoms.geom_stroke_width = geom_stroke_width;
        if (font) customGeoms.font = font;
        if (fontSize) customGeoms.fontSize = fontSize;
        if (font) customGeoms.font = font;
        if (fontSize) customGeoms.fontSize = fontSize;
        if (align) customGeoms.align = align;
        if (baseline) customGeoms.baseline = baseline;
        if (fontStrokeWidth) customGeoms.fontStrokeWidth = fontStrokeWidth;
        if (fontStrokeColor) customGeoms.fontStrokeColor = fontStrokeColor;
        if (fontFillColor) customGeoms.fontFillColor = fontFillColor;
        if (placement) customGeoms.placement = placement;
        if (maxReso) customGeoms.maxReso = maxReso;
        if (display) customGeoms.display = display;

        addGeomToVectorLayer(
          _map,
          event.data.uuid,
          event.data.layerName,
          event.data.geom_astext,
          customGeoms,
          event.data.label
        );
        return;
      case "removeGeometryFromLayer":
        logger.log("removeGeometryFromLayer", event.data);
        removeGeomFromLayer(_map, event.data.uuid, event.data.layerName);
        break;
      case "initMeasure":
        logger.log("initMeasure", event.data);
        console.log("initMeasure", event.data);
        if (event.data.measure !== "line" && event.data.measure !== "area") {
          sendMessageToParent({
            type: "error",
            error: "type must be 'line' or 'area'",
          });
          return;
        }
        if (event.data.text)
          setToolsHelpersTexts({
            textStart: event.data.text.textStart,
            textContinue: event.data.text.textContinue,
          });
        setToolMeasure(event.data.measure);
        break;
      case "cancelMeasure":
        logger.log("cancelMeasure", event.data);
        vectorLayerRef.current.clear();
        setToolMeasure(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    _wmsLayers = wmsLayers;
  }, [wmsLayers]);
  useEffect(() => {
    _toc = Toc;
  }, [Toc]);
  useEffect(() => {
    _ProjectProperties = ProjectProperties;
  }, [ProjectProperties]);
  useEffect(() => {
    _GiswaterFilters = GiswaterFilters;
  }, [GiswaterFilters]);
  useEffect(() => {
    _activeLayer = activeLayer;
  }, [activeLayer]);
  useEffect(() => {
    _extent = extent;
  }, [extent]);

  useEffect(() => {
    _map = map;
  }, [map]);
  useEffect(() => {
    _ProjectLayers = layers;
    // setLayers(layers);
  }, [layers]);

  useEffect(() => {
    _selectedTiled = selectedTiled;
  }, [selectedTiled]);
  useEffect(() => {
    _availableLayers = availableLayers;
    // setLayers(layers);
  }, [availableLayers]);
  useEffect(() => {
    _currentZoom = zoomLevel;
  }, [zoomLevel]);
};
