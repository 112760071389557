import React, { useContext, useEffect, useState } from "react";
import MapContext from "../../Contexts/MapContext.js";
import WmsService from "../../Shared/wms-service";
import RichLogger from "../../Shared/richlogger";

import Layers from "../Layers";

const GetWMSElements = (props) => {
  const logger = new RichLogger("components/getWMSTable");
  const { map, doElementsFromLayer, activeLayer, extent } =
    useContext(MapContext);
  const { sendMessageToParent, token, name, project_id, api_uri } = props;

  useEffect(() => {}, []);

  useEffect(() => {
    if (!map) return;
  }, [map]);

  useEffect(() => {
    if (!doElementsFromLayer) return;
    const { layer, limit, format } = doElementsFromLayer;
    logger.log("GetWMSElements", {
      layer: layer,
      limit: isNaN(parseInt(limit)) ? limit : 100,
      format: format,
      extent,
    });

    logger.log("GetWMSElements");
    if (layer === null || layer === "") {
      logger.log(
        "GetWMSElements no layer requested, use activeLayer",
        activeLayer
      );
      if (activeLayer === null) {
        sendMessageToParent({
          type: "error",
          error: "No active layer",
        });
        return;
      }
      layer = activeLayer;
    }

    //Math.min(Math.max(viewResolution*sensibilityFactor,1),1) TODO sensibility factor
    WmsService.GetWMSElements({
      map: map,
      layers: Layers.getLayerList(),
      coordinates: map.getView().getCenter(),
      activeLayer: layer,
      token: token,
      name: name,
      project_id: project_id,
      extent,
      limit: limit ? parseInt(limit) : 100,
      format,
    })
      .then((msg) => {
        logger.success("GetWMSElements", msg.data);
        sendMessageToParent({
          type: "layerElements",
          data: msg.data,
        });
      })
      .catch((e) => {
        logger.error("GetWMSElements", e);
        console.error("GetWMSElements", e);
      });
  }, [doElementsFromLayer]);

  return <React.Fragment></React.Fragment>;
};
export default GetWMSElements;
