import { useState, useEffect, useCallback, useContext } from "react";
import MapContext from "../Contexts/MapContext.js";
const useLayers = (type) => {
  const [rawLayers, setRawLayers] = useState(null);
  const [projectLayers, setProjectLayers] = useState(null);
  const { setToc, setAvailableLayers } = useContext(MapContext);
  useEffect(() => {
    if (type !== "giswater") return;
    if (!projectLayers) return;
    if (!rawLayers) return;
    formatLayers(type);
  }, [rawLayers, projectLayers, type]);

  const formatLayers = useCallback(
    (type) => {
      let available_layers = [];
      //first Level
      for (var i = 0; i < rawLayers.length; i++) {
        rawLayers[i].isContainer = false;
        rawLayers[i].isActiveLayer = false;
        available_layers.push(rawLayers[i]);
        //second level
        if (typeof rawLayers[i].Layer != "undefined") {
          rawLayers[i].isContainer = true;
          for (var s = 0; s < rawLayers[i].Layer.length; s++) {
            rawLayers[i].Layer[s].isContainer = false;
            rawLayers[i].Layer[s].isActiveLayer = false;
            available_layers.push(rawLayers[i].Layer[s]);
            //third level
            if (typeof rawLayers[i].Layer[s].Layer != "undefined") {
              rawLayers[i].Layer[s].isContainer = true;
              rawLayers[i].Layer[s].isActiveLayer = false;
              for (var t = 0; t < rawLayers[i].Layer[s].Layer.length; t++) {
                //fourth level
                rawLayers[i].Layer[s].Layer[t].isContainer = false;
                rawLayers[i].Layer[s].Layer[t].isActiveLayer = false;

                if (
                  typeof rawLayers[i].Layer[s].Layer[t].Layer != "undefined"
                ) {
                  rawLayers[i].Layer[s].Layer[t].isContainer = true;
                }
                available_layers.push(rawLayers[i].Layer[s].Layer[t]);
              }
            }
          }
        }

        if (i === rawLayers.length - 1) {
          if (type === "giswater") {
            formatGiswaterToc(rawLayers, projectLayers, available_layers);
          } else {
            setToc(rawLayers);
          }
        }
      }
    },

    [rawLayers, projectLayers]
  );

  const formatGiswaterToc = (rawLayers, projectLayers, available_layers) => {
    //rawLayers contains Toc
    for (var i = 0; i < rawLayers.length; i++) {
      let projectLayerData = getLayerByQgisName(
        projectLayers,
        rawLayers[i].Name
      );
      if (projectLayerData.length > 0) {
        rawLayers[i].layer_public = projectLayerData[0].layer_public;
        rawLayers[i].layer_transparent = projectLayerData[0].layer_transparent;
        rawLayers[i].gutter = projectLayerData[0].gutter;
        rawLayers[i].edit = projectLayerData[0].edit;
        rawLayers[i].db_table = projectLayerData[0].db_table;
        rawLayers[i].singletile = projectLayerData[0].singletile;
        rawLayers[i].visitable = projectLayerData[0].visitable;
        rawLayers[i].qgis_name = projectLayerData[0].qgis_name;
      }
    }
    //available_layers contains all layers as an array
    for (i = 0; i < available_layers.length; i++) {
      let projectLayerData = getLayerByQgisName(
        projectLayers,
        available_layers[i].Name
      );
      if (projectLayerData.length > 0) {
        available_layers[i].layer_public = projectLayerData[0].layer_public;
        available_layers[i].layer_transparent =
          available_layers[0].layer_transparent;
        available_layers[i].gutter = projectLayerData[0].gutter;
        available_layers[i].edit = projectLayerData[0].edit;
        available_layers[i].db_table = projectLayerData[0].db_table;
        available_layers[i].singletile = projectLayerData[0].singletile;
        available_layers[i].visitable = projectLayerData[0].visitable;
        available_layers[i].qgis_name = projectLayerData[0].qgis_name;
      }
    }
    setToc(rawLayers);
    setAvailableLayers(available_layers);
  };

  const getLayerProperties = (_layers, name) => {
    if (_layers.length === 0) return {};
    const result = _layers.find((item) => item.qgis_name === name);
    if (result) {
      result.layer_transparent = result.layer_transparent
        ? result.layer_transparent
        : true;
      result.singletile = result.singletile ? result.singletile : false;
      result.gutter = result.gutter ? result.gutter : 0;
      return result;
    }
    return {
      layer_transparent: true,
      gutter: 0,
    };
  };
  const getLayerByQgisName = (data, qgisName) => {
    return data.filter(function (data) {
      return data.qgis_name === qgisName;
    });
  };

  return {
    formatLayers,
    rawLayers,
    setRawLayers,
    setProjectLayers,
    getLayerProperties,
  };
};
export default useLayers;
