import RichLogger from "../richlogger";
var _layers = [];
const FormatToc = (user_layers, wmsLayers) => {
  const logger = new RichLogger("Toc");
  _layers = user_layers;
  return user_layers;
};
const getLayerProperties = (name) => {
  if (_layers.length === 0) return {};
  const result = _layers.find((item) => item.qgis_name === name);
  result.layer_transparent = result.layer_transparent
    ? result.layer_transparent
    : true;
  result.singletile = result.singletile ? result.singletile : false;
  result.gutter = result.gutter ? result.gutter : 0;
  if (result) return result;
  return {};
};

const Toc = {
  FormatToc,
  getLayerProperties,
};

export default Toc;
