import React, { useContext, useEffect, useState } from "react";
import TileLayer from "../Layers/TileLayer";
/*import { osm, xyz } from "../Source";
import { TileWMS, WMTS } from "ol/source";
import { optionsFromCapabilities } from "ol/source/WMTS";
import { Attribution } from "ol/control";
import ApiService from "../../Shared/api-service";
import WmsService from "../../Shared/wms-service";*/
import MapContext from "../../Contexts/MapContext.js";
import RichLogger from "../../Shared/richlogger";
import useBackgrounds from "../../hooks/useBackgrounds";

const logger = new RichLogger("components/Backgrounds");
const Backgrounds = (props) => {
  const { map, renderBg, setRenderBg, sourceBg, setSourceBg, bg, setBg } =
    useContext(MapContext);

  //const [bg] = useState(props.bg);
  const [api_uri] = useState(props.api_uri);
  const [token] = useState(props.token);
  const { getBackground } = useBackgrounds(props);
  useEffect(() => {
    if (!bg) return;
    if (!renderBg) {
      logger.log("Backgrounds loaded", props);
      getBackground(bg, api_uri, token)
        .then((msg) => {
          setSourceBg(msg);
          setRenderBg(true);
        })
        .catch((e) => {});
    }
  }, [renderBg, bg, api_uri, token]);

  useEffect(() => {
    setBg(props.bg);
  }, [props.bg, setBg]);

  return (
    <React.Fragment>
      <TileLayer
        source={sourceBg}
        zIndex={0}
        name="background"
        render={renderBg}
      ></TileLayer>
    </React.Fragment>
  );
};
export default Backgrounds;
