import axios from "axios";
import { WMSCapabilities, WMTSCapabilities } from "ol/format/";
import { getWidth, getHeight } from "ol/extent";
import RichLogger from "./richlogger";
const getCapabilities = (props) => {
  const logger = new RichLogger("wms-service");
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}wms?service=WMS&VERSION=1.0.0&REQUEST=GetCapabilities`;
    logger.log(`GetCapabilities`, requestUrl);
    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }
    if (props.type) {
      requestUrl += `&type=${props.type}`;
    }
    logger.log(`GetCapabilities`, requestUrl);
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`GetCapabilities`, response);
        try {
          var parser = new WMSCapabilities();
          var capabilities = parser.read(response.data);
          let extent = null;
          let srid = null;
          let GetFeatureInfo = null;
          logger.log("getCapabilities response", capabilities);
          if (
            typeof capabilities.Capability.Layer.BoundingBox != "undefined" &&
            typeof capabilities.Capability.Layer.BoundingBox[0] != "undefined"
          ) {
            extent = capabilities.Capability.Layer.BoundingBox[0].extent;
            srid = capabilities.Capability.Layer.BoundingBox[0].crs;
          } else {
            extent =
              capabilities.Capability.Layer.Layer[0].BoundingBox[0].extent;
          }
          if (typeof capabilities.Capability.Request != "undefined") {
            if (
              typeof capabilities.Capability.Request.GetFeatureInfo !=
              "undefined"
            )
              GetFeatureInfo = true;
          }
          let res = {
            capabilities: capabilities,
            layers: capabilities.Capability.Layer.Layer,
            extent: extent,
            epsg: srid,
            GetFeatureInfo: GetFeatureInfo,
          };
          resolve(res);
        } catch (e) {
          logger.error("getCapabilities error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("getCapabilities error", error);
        reject(error);
      });
  });
};

const getWMTSCapabilities = (props) => {
  const logger = new RichLogger("wms-service getWMTSCapabilities");

  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}wmts?service=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`;

    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }
    if (props.type) {
      requestUrl += `&type=${props.type}`;
    }
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getWMTSCapabilities`, response);
        try {
          let parser = new WMTSCapabilities();
          resolve(parser.read(response.data));
        } catch (e) {
          logger.error("getWMTSCapabilities error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("getWMTSCapabilities error", error);
        reject(error);
      });
  });
};
const getWMTSCustombg = (props) => {
  const logger = new RichLogger("wms-service getWMTSCustombg");
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}custombgwtms?service=WMTS&VERSION=1.0.0&REQUEST=GetCapabilities`;

    if (props.project_id) {
      requestUrl += `&project_id=${props.project_id}`;
    }
    if (props.name) {
      requestUrl += `&name=${props.name}`;
    }
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`getWMTSCustombg`, response);
        try {
          let parser = new WMTSCapabilities();
          resolve(parser.read(response.data));
        } catch (e) {
          logger.error("getWMTSCustombg error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("getWMTSCustombg error", error);
        reject(error);
      });
  });
};
const wmsInfo = (props) => {
  const logger = new RichLogger("wmsInfo");
  return new Promise((resolve, reject) => {
    if (!props.activeLayer) reject("no active layer");
    let infoOptionsObj = {
      INFO_FORMAT: props.format === "json" ? "application/json" : "text/xml",
    };
    if (typeof props.layers != "undefined") {
      logger.log("wmsInfo()", props.layers);
      infoOptionsObj["QUERY_LAYERS"] = props.layers;
    }

    let _source = null;
    //find active layer source
    props.map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === props.activeLayer) {
        _source = layer.getSource();
      }
    });

    var url = _source.getFeatureInfoUrl(
      props.coordinates,
      props.map.getView().getResolution() * 5,
      props.map.getView().getProjection(),
      infoOptionsObj
    );
    let requestUrl = `${url}&project_id=${props.project_id}&name=${props.name}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`wmsInfo`, response);
        try {
          resolve(response);
        } catch (e) {
          logger.error("wmsInfo error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("wmsInfo error", error);
        reject(error);
      });
  });
};
const GetWMSElements = (props) => {
  const logger = new RichLogger("GetWMSElements");
  return new Promise((resolve, reject) => {
    if (!props.activeLayer) reject("no active layer");
    let infoOptionsObj = {
      INFO_FORMAT: props.format === "json" ? "application/json" : "text/xml",
      FEATURE_COUNT: props.limit,
      cql_filter: `BBOX(geom, ${props.extent.join(",")} )`,
    };
    if (typeof props.layers != "undefined") {
      logger.log("GetWMSElements()", props.layers);
      infoOptionsObj["QUERY_LAYERS"] = props.layers;
    }
    let _source = null;
    //find active layer source
    props.map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === props.activeLayer) {
        _source = layer.getSource();
      }
    });
    var viewResolution = props.map.getView().getResolution();
    var url = _source.getFeatureInfoUrl(
      props.map.getView().getCenter(),
      viewResolution *
        Math.max(getWidth(props.extent), getHeight(props.extent) / 100),
      props.map.getView().getProjection(),
      infoOptionsObj
    );
    let requestUrl = `${url}&project_id=${props.project_id}&name=${props.name}`;
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`GetWMSElements`, response);
        try {
          resolve(response);
        } catch (e) {
          logger.error("GetWMSElements error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("GetWMSElements error", error);
        reject(error);
      });
  });
};
const WFSrequest = (props) => {
  const logger = new RichLogger("WFSrequest");
  return new Promise((resolve, reject) => {
    let requestUrl = `${props.api_uri}${props.request}&project_id=${props.project_id}&name=${props.name}`;

    logger.log(`WFSrequest requestUrl`, requestUrl);
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${props.token}` } })
      .then((response) => {
        logger.log(`WFSrequest`, response);
        try {
          resolve(response);
        } catch (e) {
          logger.error("WFSrequest error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("WFSrequest error", error);
        reject(error);
      });
  });
};

const ProxyWFSrequest = (api_uri, token, request) => {
  const logger = new RichLogger("ProxyWFSrequest");
  return new Promise((resolve, reject) => {
    let requestUrl = `${api_uri}proxygeoserver?uri=${request}`;

    logger.log(`WFSrequest requestUrl`, requestUrl);
    axios
      .get(requestUrl, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        logger.log(`WFSrequest`, response.data);
        try {
          resolve(response.data);
        } catch (e) {
          logger.error("WFSrequest error", e);
          reject(e);
        }
      })
      .catch((error) => {
        logger.error("WFSrequest error", error);
        reject(error);
      });
  });
};

const parseCapabilieties = (xml) => {
  const logger = new RichLogger("parseCapabilieties");
  return new Promise((resolve, reject) => {
    try {
      let parser = new WMTSCapabilities();
      resolve(parser.read(xml));
    } catch (e) {
      logger.error("parseCapabilieties error", e);
      reject(e);
    }
  });
};

const WmsService = {
  getCapabilities,
  wmsInfo,
  getWMTSCapabilities,
  WFSrequest,
  getWMTSCustombg,
  ProxyWFSrequest,
  parseCapabilieties,
  GetWMSElements,
};

export default WmsService;
