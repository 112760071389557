import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import Logger from "../Shared/richlogger";
import jwt from "jsonwebtoken";
import BadParams from "../Components/BadParams";
import { setDevBugCookie } from "../Shared/cookies";
const logger = new Logger("withIframeParams");
let extent = null;
let domId = "map-frame";
function withIframeParams(BaseComponent) {
  return function EnhancedComponent() {
    const search = QueryString.parse(useLocation().search);

    if (search.domId !== undefined) domId = search.domId;
    let decodedParams = {};
    try {
      decodedParams = jwt.verify(
        search.sessionToken,
        process.env.REACT_APP_JWT_PUBLIC_KEY,
        { algorithms: ["RS256"] }
      ).data;
    } catch (e) {
      console.error("Error decomposing sessionToken", e.message);
    }
    let show_layers = [];

    if (decodedParams.show_layers) {
      try {
        show_layers = decodedParams.show_layers.split(",");
      } catch (e) {}
    }

    //format extent
    if (decodedParams.extent) {
      let preextent = decodedParams.extent.split(",");
      if (preextent.length !== 4)
        return <BadParams error="bad format for extent" />;
      extent = preextent.map(function (num) {
        return parseInt(num);
      });
    }
    const iframeParams = {
      // All params are required, unless stated otherwise:
      token: decodedParams.token ? decodedParams.token : null,
      name: decodedParams.name,
      zoom: decodedParams.zoom ? parseInt(decodedParams.zoom) : 0,
      background: decodedParams.background,
      project_id: decodedParams.id ? decodedParams.id : null,
      api_uri: decodedParams.api,
      logo: decodedParams.logo, //override Logo to display
      active_layer: decodedParams.active_layer
        ? decodedParams.active_layer
        : null,
      show_layers: show_layers,
      geoservice: decodedParams.geoservice ? decodedParams.geoservice : "",
      type: decodedParams.type ? decodedParams.type : "giswater", //giswater or vidromaps
      service_uri: decodedParams.service_uri ? decodedParams.service_uri : null,
      srid: decodedParams.srid ? decodedParams.srid : null, //override project srid
      extent: decodedParams.extent ? extent : null, //override Project extent
      use_giswater_tiled: decodedParams.use_giswater_tiled
        ? decodedParams.use_giswater_tiled
        : false, //load giswater tiled background on load map
      domId: domId,
      debug: decodedParams.debug ? decodedParams.debug : false,
    };
    setDevBugCookie({ debug: iframeParams.debug });
    logger.info("Loaded params", iframeParams);
    const areIframeParamsValid = () => {
      let valid = true; // Valid by default
      let requiredParams = ["name"];
      if (iframeParams.type === "giswater") requiredParams = ["name", "token"];

      // Validate param:
      const isParamValid = (param) => {
        if (!iframeParams[param]) {
          logger.log(`Invalid ${param}`);
          valid = false;
        }
      };
      // Validate all params:
      requiredParams.forEach((param) => isParamValid(param));

      return valid;
    };

    if (!areIframeParamsValid()) {
      return <BadParams />;
    }

    return (
      <BaseComponent
        iframeParams={iframeParams}
        areIframeParamsValid={areIframeParamsValid()}
      />
    );
  };
}

export default withIframeParams;
