import RichLogger from "../richlogger";
import * as SRIDS from "./srids";
import axios from "axios";
const logger = new RichLogger("srids");
export const getSridData = async (srid) => {
  return new Promise((resolve, reject) => {
    if (!srid) return;
    let epsgid = srid;

    if (isNaN(srid) && srid.includes(":")) {
      epsgid = srid.split(":")[1];
    }

    logger.log("getSridData", {
      data: SRIDS[`EPSG_${epsgid}`],
      srid: srid,
      id: epsgid,
    });
    if (SRIDS[`EPSG_${epsgid}`] === undefined) {
      logger.info("srid not found on local srids, get remote data");
      getRemoteSrid(epsgid)
        .then((msg) => {
          resolve(msg);
        })
        .catch((e) => {
          logger.error("getSridData not found, using default EPSG:25831");
          resolve(SRIDS.EPSG_25830); //default srid
        });
    } else {
      logger.info("srid found on local srids");
      resolve(SRIDS[`EPSG_${epsgid}`]);
    }
  });
};

const getRemoteSrid = (srid) => {
  logger.log(`getRemoteSrid`, srid);
  return new Promise((resolve, reject) => {
    axios
      .get(`https://epsg.io/${srid}.js`)
      .then((response) => {
        logger.success(`getRemoteSrid`, response.data);
        if (isHTML(response.data)) reject("invalid srid");
        let str = response.data.split('","');
        resolve(str[1].slice(0, -3));
      })
      .catch((error) => {
        logger.error("getRemoteSrid error", error);
        reject(error);
      });
  });
};

const isHTML = (str) => {
  return /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(str);
};
