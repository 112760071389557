import React, { useContext, useEffect, useState } from "react";
import MapContext from "../../../Contexts/MapContext.js";
import eventEmitter from "../../../Shared/events";
import { sendMessageToParent } from "../../../Shared/iframe-communicator";
//OL
import Draw from "ol/interaction/Draw";
import { Circle, Fill, Stroke, Style } from "ol/style";
import { Polygon, LineString, MultiLineString } from "ol/geom";
import Overlay from "ol/Overlay";
import RichLogger from "../../../Shared/richlogger";

import "./index.scss";
const logger = new RichLogger("components/Tools/Measure/measure");
//Message to show when the user is drawing a line.

let helpTooltipElement = null;
let helpTooltip = null;
let drawStartEvent = null;
let drawEndEvent = null;
let sketch = null;
let draw = null;
let output = null;
let helpMsg = null;
const Measure = (props) => {
  const { map, setAddedGeom, ToolMeasure } = useContext(MapContext);
  const [render, setRender] = useState(false);
  const { measure_fill_color, measure_stroke_color } = props.customGeoms;
  const drawStyle = new Style({
    fill: new Fill({
      color: measure_fill_color,
    }),
    stroke: new Stroke({
      color: measure_stroke_color,
      lineDash: [10, 10],
      width: 2,
    }),
    image: new Circle({
      radius: 4,
      fill: new Fill({
        color: measure_fill_color,
      }),
      stroke: new Stroke({
        color: measure_stroke_color,
        width: 2,
      }),
    }),
  });
  helpMsg = props.text.textStart;
  const addInteraction = (type) => {
    logger.log("addInteraction()", type);
    draw = new Draw({
      source: props.vectorLayerSource,
      type: type,
      style: drawStyle,
    });

    map.addInteraction(draw);

    createHelpTooltip();

    drawStartEvent = draw.on(
      "drawstart",
      function (evt) {
        logger.log("drawstart");
        // set sketch
        sketch = evt.feature;
      },
      this
    );

    drawEndEvent = draw.on(
      "drawend",
      function (evt) {
        logger.log("drawend");
        setAddedGeom(sketch.getGeometry());
        if (draw) {
          map.removeInteraction(draw);
          draw = null;
        }
        eventEmitter.removeAllListeners();
        sendMessageToParent({
          type: "MeasureEnd",
          measure: ToolMeasure,
          result: output,
        });
      },
      this
    );
  };

  const removeToolTip = () => {
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      helpTooltipElement = null;
    }
  };
  const createHelpTooltip = () => {
    logger.log("createHelpTooltip");
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = "tooltipbase";
    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left",
    });
    map.addOverlay(helpTooltip);
  };

  const pointerMoveHandler = (evt) => {
    if (evt.dragging) {
      return;
    }
    var tooltipCoord = evt.coordinate;
    if (sketch) {
      var geom = sketch.getGeometry();
      if (geom instanceof Polygon) {
        output = formatArea(geom);
        helpMsg = props.text.textContinue;
        tooltipCoord = geom.getInteriorPoint().getCoordinates();
      } else if (
        geom instanceof LineString ||
        geom instanceof MultiLineString
      ) {
        helpMsg = props.text.textContinue;
        output = formatLength(geom);
        tooltipCoord = geom.getLastCoordinate();
      }
    }
    if (helpTooltipElement) {
      helpTooltipElement.innerHTML = output ? output : helpMsg;
      helpTooltip.setPosition(evt.coordinate);
    }
  };

  const formatLength = (line) => {
    if (!line) return;
    var length = Math.round(line.getLength() * 100) / 100;
    var output;
    if (length > 100) {
      output = `${Math.round((length / 1000) * 100) / 100} km`;
    } else {
      output = `${Math.round(length * 100) / 100} m`;
    }
    return output;
  };

  const formatArea = (polygon) => {
    var area = polygon.getArea();
    var output;
    if (area > 10000) {
      output = `${Math.round((area / 1000000) * 100) / 100}km<sup>2</sup>`;
    } else {
      output = `${Math.round(area * 100) / 100} m<sup>2</sup>`;
    }
    return output;
  };
  //*****************************************************************/
  //*******************           EFFECTS          ******************/
  //*****************************************************************/
  useEffect(() => {
    logger.log("Measureline tool rendered", props.tool);
    eventEmitter.on("pointermove", (evt) => {
      pointerMoveHandler(evt);
    });
    if (props.tool === "area") {
      addInteraction("Polygon");
    } else if (props.tool === "line") {
      addInteraction("MultiLineString");
    }

    return () => {
      //remove event listeners
      eventEmitter.removeAllListeners();
      //remove interactions
      if (draw) {
        map.removeInteraction(draw);
        draw = null;
      }
      removeToolTip();
    };
  }, []);

  useEffect(() => {
    if (!map) return;
    if (!ToolMeasure) {
      setRender(false);
      removeToolTip();
      return;
    }
    setRender(true);
  }, [map, ToolMeasure]);

  //*****************************************************************/
  //*******************        END EFFECTS         ******************/
  //*****************************************************************/

  return <React.Fragment></React.Fragment>;
};
export default Measure;
