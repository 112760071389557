import React, { useEffect, useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import "ol/ol.css";
import Map from "ol/Map";
import { defaults as defaultInteractions } from "ol/interaction";
import proj4 from "proj4";
import { getSridData } from "../../Shared/Srid";
import { register } from "ol/proj/proj4";
import View from "ol/View";
import MapContext from "../../Contexts/MapContext.js";
import Logo from "../Logo";
import Backgrounds from "../Backgrounds";
import TiledBackground from "../TiledBackground";
import Layers from "../Layers";

//hooks
import { Messages } from "../../Shared/Messages";
import useLayers from "../../hooks/useLayers";
//Tools
import AddPoint from "../Tools/AddPoint";
import AddPolygon from "../Tools/AddPolygon";
import Geolocalize from "../Tools/Geolocalize";
import Measure from "../Tools/Measure/measure";

import ApiService from "../../Shared/api-service";
import VidromapsService from "../../Shared/Vidromaps/vidromaps-service.js";
import WmsService from "../../Shared/wms-service";
import RichLogger from "../../Shared/richlogger";
import WKTFormatter from "../../Shared/wktformatter";
import Toc from "../../Shared/Toc";
import VectorLayer from "../Layers/VectorLayer";
import GeoJSONLayer from "../Layers/GeoJSON";
import InfoFromCoordinates from "../InfoFromCoordinates";
import GetWMSElements from "../GetWMSElements";
import { vector, wms } from "../Source";
import eventEmitter from "../../Shared/events";
import { sendMessageToParent } from "../../Shared/iframe-communicator";
import "./Map.css";
import BadParams from "../BadParams/index.js";
const proj = require("ol/proj");

let mapObject = null;
let _extent = null;
let _srid = null;

let _clickedCoordinates = null;

const logger = new RichLogger("components/VidroMap");
const VidroMap = (props) => {
  const mapRef = useRef();
  const vectorLayerRef = useRef();
  const geoJSONLayerRef = useRef();

  const {
    setMap,
    setSrid,
    addedGeom,
    setLayers,
    setClickedCoordinates,
    extent,
    setExtent,
    setTool,
    tool,
    geolocation,
    setWmsLayers,
    wmsLayers,
    ToolAddpoint,
    ToolMeasure,
    setToolAddpoint,
    ToolAddpolygon,
    setToolAddpolygon,
    ToolGeolocalize,
    GiswaterFilters,
    setGiswaterFilters,
    ProjectProperties,
    setProjectProperties,
    showTiledBg,
    setShowTiledBg,
    geojsonObject,
    setActiveLayer,
    customGeoms,
    setCustomGeoms,
    selectedTiled,
    setSelectedTiled,
    zoomLevel,
    setZoomLevel,
    ToolsHelpersTexts,
  } = useContext(MapContext);
  const dispatch = useDispatch();

  const [initialLayers, setInitialLayers] = useState(false);

  const [vectorLayerSource] = useState(vector({}));
  const [changingResolution, setChangingResolution] = useState(false);
  const [ParamsError, setParamsError] = useState(false);
  //Iframe messages handler
  Messages(props, vectorLayerRef, geoJSONLayerRef);
  //layers
  const { formatLayers, setRawLayers, setProjectLayers } = useLayers(
    props.iframeParams.type
  );

  //remove rotation interactions
  const interactions = defaultInteractions({
    altShiftDragRotate: false,
    pinchRotate: false,
    doubleClickZoom: false,
    mouseWheelZoom: true,
  });

  //*****************************************************************/
  //*******************           EFFECTS          ******************/
  //*****************************************************************/

  // on component mount
  useEffect(() => {
    //giswater project
    if (props.iframeParams.type.toLowerCase() === "giswater") {
      //load project properties
      ApiService.getProject(props.iframeParams)
        .then((result) => {
          logger.success("getProject", result);
          setProjectProperties(result);
          setCustomGeoms({
            ...customGeoms,
            geom_stroke_color: result.geom_select_stroke_color,
            geom_fill_color: result.geom_select_fill_color,
            measure_fill_color: result.measure_fill_color,
            measure_stroke_color: result.measure_stroke_color,
          });
          setSelectedTiled(result.selected_tiled);
          if (result.use_tiled_background) {
            //if tiled background is available and map is configured for use tiled by default, show tiled
            setShowTiledBg(true);
            //notify UI tiled background is available
            sendMessageToParent({
              type: "giswaterTiledBackgroundAvailable",
              available: true,
            });
          }
        })
        .catch((e) => {
          logger.error("getProject", e);
        });
      //load project layers
      ApiService.getLayers(props.iframeParams)
        .then((result) => {
          logger.success("getLayers", result);
          setLayers(Toc.FormatToc(result), wmsLayers);
          setProjectLayers(result);
        })
        .catch((e) => {
          logger.error("getLayers", e);
        });
      ApiService.getFilters(props.iframeParams)
        .then((result) => {
          logger.success("getFilters", result);
          setGiswaterFilters(result);
        })
        .catch((e) => {
          logger.error("getFilters", e);
        });
    } else {
      //Open vidromaps project
      //TODO esto que pinta aqui
      //loadInitialLayers(props, []);
    }

    //mpaHandler js listener
    /* window.addEventListener("message", (evt) =>
      iframeCommunicator.onMessageReceived(evt, processMessage)
    );*/

    setMap(null);

    if (props.iframeParams.srid) {
      logger.info("using JWT srid", props.iframeParams.srid);
      _srid = props.iframeParams.srid;
      if (!isNaN(_srid)) {
        _srid = `EPSG:${props.iframeParams.srid}`;
      }
      setProj4Srid(_srid);
    }
    if (props.iframeParams.extent) {
      logger.info("using JWT extent", props.iframeParams.extent);
      _extent = props.iframeParams.extent;
    }
    //vidromaps
    if (
      !props.iframeParams.service_uri &&
      props.iframeParams.type.toLowerCase() !== "giswater"
    ) {
      VidromapsService.vidromapIsPublished(props.iframeParams).then(
        (published) => {
          if (published) {
            if (props.iframeParams.srid) {
              _srid = props.iframeParams.srid;
              setProj4Srid(_srid);
            }

            if (props.iframeParams.extent) {
              _extent = props.iframeParams.extent;
            }

            if (props.iframeParams.geoservice.toUpperCase() === "QGIS") {
              logger.info("vidromaps with qgis server", props.iframeParams);

              WmsService.getCapabilities(props.iframeParams)
                .then((msg) => {
                  logger.success("getCapabilities", msg);
                  processCapabilities(msg);
                })
                .catch((e) => {
                  logger.error("getCapabilities", e);
                });
            } else {
              setSrid(_srid);
              // dispatch(setZoom(props.iframeParams.zoom));
              setZoomLevel(Number(props.iframeParams.zoom));

              setExtent(_extent);
              renderMap(props);
            }
          } else {
            setParamsError("Map is not published");
          }
        }
      );
    } else {
      //giswater map or vidromap with geoserver
      WmsService.getCapabilities(props.iframeParams)
        .then((msg) => {
          logger.success("getCapabilities", msg);
          processCapabilities(msg);
        })
        .catch((e) => {
          logger.error("getCapabilities", e);
        });
    }
  }, [setShowTiledBg]);

  // zoom change handler
  /*useEffect(() => {
    if (!mapObject) return;
    mapObject.getView().setZoom(zoom);
    sendMessageToParent({
      type: "onZoomChange",
      zoom: zoom,
    });
  }, [zoom, mapObject]);*/

  // zoom change handler
  useEffect(() => {
    if (!mapObject) return;
    logger.log("zoomLevel changed", zoomLevel);
    mapObject.getView().setZoom(zoomLevel);
    sendMessageToParent({
      type: "onZoomChange",
      zoom: zoomLevel,
      meta: {
        maxZoom: mapObject.getView().getMaxZoom(),
        minZoom: mapObject.getView().getMinZoom(),
        zoom: mapObject.getView().getZoom(),
        extent: _extent,
      },
    });
  }, [zoomLevel, mapObject]);

  // filters change handler
  useEffect(() => {
    logger.info("GiswaterFilters changed", GiswaterFilters);
    Layers.resetCachedLayers();
    Layers.reloadDisplayedLayers(mapObject, GiswaterFilters);
    sendMessageToParent({
      type: "GiswaterFiltersApplied",
      filters: GiswaterFilters,
    });
  }, [GiswaterFilters]);

  // changingResolution handler
  useEffect(() => {
    if (!mapObject) return;

    logger.info("changingResolution changed", changingResolution);
    setZoomLevel(mapObject.getView().getZoom());
    //dispatch(setZoom(mapObject.getView().getZoom()));
  }, [changingResolution, mapObject]);

  //added geometry
  useEffect(() => {
    setToolAddpoint(false);
    setToolAddpolygon(false);
    setTool(null);

    if (addedGeom && addedGeom !== "cancelAdd") {
      logger.success("addedGeom", addedGeom);
      var rawGeometry = WKTFormatter.GeomToString(addedGeom);
      sendMessageToParent({
        type: "geomAdded",
        geom_astext: rawGeometry,
      });

      vectorLayerRef.current.addFeature(
        addedGeom,
        `geomAdded_${Math.random()}`,
        false
      );
    } else {
      logger.info("remove add Tool");
    }
  }, [addedGeom]);
  //geolocationt events
  useEffect(() => {
    if (geolocation) {
      logger.success("geolocation", geolocation);
      if (geolocation.type === "error") {
        sendMessageToParent({
          type: "error",
          error: geolocation.error,
          extraInfo: geolocation.extraInfo.message,
        });
        return;
      }

      if (geolocation.evt === "position") {
        sendMessageToParent({
          type: "geolocation",
          coordinates: geolocation.coordinates,
        });
        mapObject.getView().setCenter(geolocation.coordinates);
      } else if (geolocation.evt === "out") {
        sendMessageToParent({
          type: "geolocation",
          coordinates: geolocation.coordinates,
        });
      }
    }
  }, [geolocation]);

  useEffect(() => {
    logger.success("Extent udpdated", extent);
    _extent = extent;
  }, [extent]);
  useEffect(() => {
    logger.success("Map UPDATED");
  }, [mapObject]);

  useEffect(() => {
    if (!initialLayers && mapObject) {
      logger.log("Loading Initial layers");
      loadInitialLayers(props);
      setInitialLayers(true);
    }
  }, [mapObject, initialLayers]);

  //*****************************************************************/
  //*******************        END EFFECTS         ******************/
  //*****************************************************************/

  const cancelAdd = () => {
    setToolAddpoint(false);
    setToolAddpolygon(false);
  };

  const setProj4Srid = async (srid) => {
    logger.info("setProj4Srid", srid);
    const sridData = await getSridData(srid);
    proj4.defs(srid, sridData);
    register(proj4);
  };

  const processCapabilities = async (msg) => {
    console.log("processCapabilities", msg, _srid, _extent);
    if (!_srid) {
      logger.info("using capabilities srid", msg.epsg);
      _srid = msg.epsg;
      setSrid(_srid);
      await setProj4Srid(_srid);
    }

    if (!_extent) {
      logger.info("using capabilities extent", msg.extent);
      _extent = msg.extent;
      setExtent(_extent);
    }
    if (msg.GetFeatureInfo) {
      sendMessageToParent({
        type: "WMSInfoAvailable",
      });
    }
    if (msg.layers) {
      setRawLayers(msg.layers);
      msg.layers.map(function (x) {
        setWmsLayers((prevWmsLayers) => [...prevWmsLayers, x.Name]);
      });
    }
    sendMessageToParent({
      type: "capabilities",
    });
    renderMap(props);
  };
  const loadInitialLayers = (props) => {
    logger.info("loadInitialLayers", {
      layers: props.iframeParams.show_layers,
      filters: GiswaterFilters,
    });

    Layers.setFilters(GiswaterFilters);
    if (props.iframeParams.show_layers.length > 0) {
      props.iframeParams.show_layers.forEach((layer) => {
        const properties = Toc.getLayerProperties(layer);

        Layers.AddLayer(
          {
            name: layer,
            project_id: props.iframeParams.project_id,
            project_name: props.iframeParams.name,
            token: props.iframeParams.token,
            api_uri: props.iframeParams.api_uri,
            type: props.iframeParams.type,
            gutter: properties.gutter ? properties.gutter : 0,
            singletile: properties.singletile ? properties.singletile : true,
            transparent: properties.transparent ? properties.transparent : 1,
          },
          mapObject
        );
      });
    }
  };
  const renderMap = async (props) => {
    logger.log("renderMap", props);

    //Render Map
    const _projection = proj.get(_srid);
    let viewOptions = {
      projection: _projection,
      extent: _extent,
      center: [_extent[0], _extent[1]],
      zoom: props.iframeParams.zoom,
      smoothResolutionConstraint: false,
    };

    //if zoom level is overrided, use it. If not, will do a zoom to project extent
    if (props.iframeParams.zoom !== 0) {
      viewOptions.constrainOnlyCenter = true;
    }

    mapObject = new Map({
      layers: [],
      target: mapRef.current,
      interactions: interactions,
      view: new View(viewOptions),
    });

    // after rendering the layer, emit an event
    mapObject.once("postrender", function (event) {
      sendMessageToParent({
        type: "loaded",
        what: "map",
        meta: {
          maxZoom: mapObject.getView().getMaxZoom(),
          minZoom: mapObject.getView().getMinZoom(),
          zoom: mapObject.getView().getZoom(),
          extent: _extent,
        },
      });
      mapObject.getView().fit(_extent, mapObject.getSize());
    });

    setMap(mapObject);
    if (props.iframeParams.active_layer) {
      setActiveLayer(props.iframeParams.active_layer);
    }
    if (props.iframeParams.zoom !== 0) {
      setZoomLevel(Number(props.iframeParams.zoom));
      mapObject.getView().setZoom(props.iframeParams.zoom);
    } else {
      mapObject.getView().fit(_extent, mapObject.getSize());
      setZoomLevel(mapObject.getView().getZoom());
      mapObject.getView().setMinZoom(mapObject.getView().getZoom());
    }
    // dispatch(setZoom(props.iframeParams.zoom));

    //
    //TODO get layer list
    addListeners();
  };
  //*****************************************************************/
  //*******************      EXTERNAL MESSAGES     ******************/
  //*****************************************************************/

  //moved to Shared/Messages/index.js

  //*****************************************************************/
  //*******************    END EXTERNAL MESSAGES    *****************/
  //*****************************************************************/

  //*****************************************************************/
  //*******************          MAP LISTENERS      *****************/
  //*****************************************************************/

  const addListeners = () => {
    mapObject.on("pointerdrag", function () {
      eventEmitter.emit("pointerdrag");
    });
    mapObject.on("pointermove", function (evt) {
      eventEmitter.emit("pointermove", evt);
    });
    mapObject.on("movestart", function () {
      eventEmitter.emit("movestart");
    });
    mapObject.on("moveend", function () {
      setChangingResolution(false);
      setTimeout(() => {
        sendMessageToParent({
          type: "onCenterChange",
          coordinates: mapObject.getView().getCenter(),
        });
      }, 1000);
      setZoomLevel(mapObject.getView().getZoom());
      eventEmitter.emit("moveend");
    });
    /*mapObject.getView().on("change:resolution", function (e) {
      setChangingResolution(true);
      eventEmitter.emit("resolution");
    });*/
    mapObject.on("click", function (e) {
      logger.log("click", e.coordinate);
      eventEmitter.emit("click");
      _clickedCoordinates = e.coordinate;
      setClickedCoordinates(e.coordinate);
      sendMessageToParent({
        type: "coordinates",
        coordinates: _clickedCoordinates,
      });
    });
  };
  //use_giswater_tiled
  //*****************************************************************/
  //*******************      END MAP LISTENERS      *****************/
  //*****************************************************************/
  return (
    <React.Fragment>
      {!ParamsError && (
        <div ref={mapRef} className="ol-map">
          {props.iframeParams.background && (
            <Backgrounds
              bg={props.iframeParams.background}
              token={props.iframeParams.token}
              project_id={props.iframeParams.project_id}
              api_uri={props.iframeParams.api_uri}
              name={props.iframeParams.name}
            ></Backgrounds>
          )}
          {ProjectProperties && showTiledBg && (
            <TiledBackground
              use_tiled_background={ProjectProperties.use_tiled_background}
              selected_tiled={selectedTiled}
              token={props.iframeParams.token}
              project_id={props.iframeParams.project_id}
              api_uri={props.iframeParams.api_uri}
            ></TiledBackground>
          )}
          {ProjectProperties && (
            <VectorLayer
              source={vectorLayerSource}
              zIndex={999}
              name="draw"
              customGeoms={customGeoms}
              projectProperties={ProjectProperties}
              ref={vectorLayerRef}
            ></VectorLayer>
          )}
          {geojsonObject && (
            <GeoJSONLayer
              zIndex={998}
              projectProperties={ProjectProperties}
              customGeoms={customGeoms}
              ref={geoJSONLayerRef}
            ></GeoJSONLayer>
          )}
          {ToolAddpoint && (
            <AddPoint
              geomType="Point"
              vectorLayer={vectorLayerRef}
              customGeoms={customGeoms}
              cancelAction={cancelAdd}
            ></AddPoint>
          )}
          {ToolMeasure && (
            <Measure
              tool={ToolMeasure}
              text={ToolsHelpersTexts}
              vectorLayer={vectorLayerRef}
              customGeoms={customGeoms}
            ></Measure>
          )}
          {ToolAddpolygon && (
            <AddPolygon
              geomType="Polygon"
              projectProperties={ProjectProperties}
              customGeoms={customGeoms}
              vectorLayerSource={vectorLayerSource}
              cancelAction={cancelAdd}
              tool={tool}
            ></AddPolygon>
          )}
          {ToolGeolocalize && (
            <Geolocalize
              projectProperties={ProjectProperties}
              vectorLayerSource={vectorLayerSource}
              tool={tool}
            ></Geolocalize>
          )}
          <InfoFromCoordinates
            token={props.iframeParams.token}
            name={props.iframeParams.name}
            project_id={props.iframeParams.project_id}
            sendMessageToParent={sendMessageToParent}
            vectorLayerRef={vectorLayerRef}
            api_uri={props.iframeParams.api_uri}
          ></InfoFromCoordinates>
          <GetWMSElements
            token={props.iframeParams.token}
            name={props.iframeParams.name}
            project_id={props.iframeParams.project_id}
            sendMessageToParent={sendMessageToParent}
            api_uri={props.iframeParams.api_uri}
          ></GetWMSElements>
          <Logo logo_uri={props.iframeParams.logo}></Logo>
        </div>
      )}
      {ParamsError && <BadParams error={ParamsError}></BadParams>}
    </React.Fragment>
  );
};
export default VidroMap;
