import { useContext, useEffect, useState } from "react";
import MapContext from "../../../Contexts/MapContext.js";
import OLTileLayer from "ol/layer/Tile";
import RichLogger from "../../../Shared/richlogger";
const logger = new RichLogger("components/TileLayer");
const TileLayer = ({ source, zIndex = 0, name = null, render = false }) => {
  const { map } = useContext(MapContext);
  const [layer, setLayer] = useState(null);
  useEffect(() => {
    if (!map) return;
    if (layer) {
      map.removeLayer(layer);
      setLayer(null);
    }
    logger.log("loaded");

    let tileLayer = new OLTileLayer({
      source,
      zIndex,
      name,
    });
    map.addLayer(tileLayer);
    setLayer(tileLayer);

    tileLayer.setZIndex(zIndex);
    return () => {
      if (map) {
        setLayer(null);
        map.removeLayer(tileLayer);
      }
    };
  }, [map, source]);

  return null;
};
export default TileLayer;
