import { useState, useEffect, useContext } from "react";
import OLVectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import WKTFormatter from "../Shared/wktformatter";
import { vector } from "../Components/Source";
import Features from "../Shared/Features";
import RichLogger from "../Shared/richlogger";
import MapContext from "../Contexts/MapContext.js";

const logger = new RichLogger("hooks/useVectorLayers");
const useVectorLayers = (props) => {
  const { map, vectorLayers, setVectorLayers } = useContext(MapContext);
  const [newLayer, setNewLayer] = useState(null);

  useEffect(() => {
    if (!map) return;
    if (!newLayer) return;
    if (!vectorLayers.find((element) => newLayer)) {
      setVectorLayers((prevLayers) => [...prevLayers, newLayer]);
      setNewLayer(null);
      logger.log("vectorLayers added layer", newLayer);
      map.addLayer(
        new OLVectorLayer({
          updateWhileInteracting: true,
          source: vector({}),
          name: newLayer,
        })
      );
    }
  }, [newLayer, vectorLayers, map]);

  const addGeomToVectorLayer = (
    map,
    uuid,
    layerName,
    geom_astext,
    customGeoms,
    label
  ) => {
    logger.log("addGeomToVectorLayer", {
      map,
      layerName,
      layers: map.getLayers(),
      customGeoms,
      geom_astext,
    });
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === layerName) {
        var rawGeometry = WKTFormatter.StringToGeom(geom_astext);
        layer.setStyle(
          Features.setStyle(customGeoms, label, map.getView().getResolution())
        );
        let feu = Features.createFeature(rawGeometry, uuid, customGeoms, label);
        layer.getSource().addFeature(feu);
      }
    });
  };

  const addVectorLayer = ({ name }) => {
    setNewLayer(name);
  };

  const setLayerStyle = (style) => {
    //  Features.setLayerStyle()
  };
  const removeGeomFromLayer = (map, id, layerName) => {
    logger.log("removeGeomFromLayer", id);
    map.getLayers().forEach((layer) => {
      if (layer.get("name") && layer.get("name") === layerName) {
        let features = layer.getSource().getFeatures();
        var found = false;
        for (var i = 0; i < features.length && !found; i++) {
          if (features[i].getId() === id) {
            found = true;
            logger.log("removing feature", id);
            layer.getSource().removeFeature(features[i]);
          }
        }
      }
    });
  };
  return {
    addVectorLayer,
    addGeomToVectorLayer,
    removeGeomFromLayer,
  };
};
export default useVectorLayers;
